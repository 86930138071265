import { Button, Card, Form, Input, Popconfirm, Table, message } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { TrpcBase } from '../../../utils/trpc';

export default function BuildingTeam() {
	const { id } = useParams();
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	if (!id) {
		return <div>not id</div>;
	}

	const teams = TrpcBase.apartment.teamList.useQuery(id);
	const addTeam = TrpcBase.apartment.addTeam.useMutation();
	const delTeam = TrpcBase.apartment.delTeam.useMutation();

	const onFinish = async (values: any) => {
		console.log('Success:', values);
		try {
			const aa = await addTeam.mutateAsync({
				buildingId: id,
				userId: values.userId,
			});

			messageApi.success('添加成功');
			await teams.refetch();
		} catch (e) {
			console.log(`err`, e);
			messageApi.error(e.message);
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div className="m-2">
			<div className="grid md:grid-row-2 gap-3">
				{contextHolder}
				<Card title="输入账号，加入楼栋团队">
					<Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
						<Form.Item name="userId" label={'用户id'}>
							<Input />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 4, span: 20 }}>
							<Button type="primary" htmlType="submit">
								提交
							</Button>
						</Form.Item>
					</Form>
				</Card>

				<Card title="当前楼栋团队">
					<Table
						className="overflow-y-auto"
						scroll={{ x: 1500 }}
						columns={[
							{ title: '用户id', dataIndex: 'userId' },
							{ title: '用户邮箱', dataIndex: 'primaryEmail' },
							{ title: '用户名', dataIndex: 'username' },
							{ title: '用户角色', dataIndex: 'buildingRole' },
							{
								title: '操作',
								dataIndex: 'userId',
								render: (i, record) => {
									if (record.buildingRole === 'master') {
										return '-';
									}

									return (
										<Popconfirm
											title={'确定删除吗'}
											onConfirm={async () => {
												const r = await delTeam.mutateAsync({
													buildingId: id,
													userId: i,
												});

												messageApi.success('删除成功');
												await teams.refetch();
											}}
										>
											<Button type="default">删除</Button>
										</Popconfirm>
									);
								},
							},
						]}
						dataSource={teams.data}
						rowKey={'userId'}
					/>
				</Card>
			</div>
		</div>
	);
}
